#footer {
    background-color: rgb(219, 219, 219);
    padding-top: 10px;
    padding-bottom: 10px;
}

#footer img {
    width: auto;
    height: 50px;
}

#footer .footer-icon {
    font-size: 24px;
    /* text-decoration: underline; */
    /* transition: 0.3s all ease-in-out; */
}

#footer .footer-link {
    margin-top: 10px;
    margin-bottom: 5px;
}

#footer .icon-desc {
    margin-left: 10px;
}

#footer a {
    color: black;
    text-decoration: none;
    font-size: 14px;
}

#footer a:hover {
    text-decoration: underline;
    color: #2a9df4 !important;
    cursor: pointer;
}

#footer .footer-link:hover .footer-icon {
    /* font-size: 28px; */
    /* text-decoration: underline;
    transition: 0.3s all ease-in-out; */
    cursor: pointer;
}

#footer .copyright {
    color: rgb(146, 146, 146);
    padding-top: 5px;
}