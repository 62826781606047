#Home .header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../images/hero-image.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
    background-color: #d5edff;
    height: 100vh;
    /* width: 100%; */
    /* height: 100%;  */
}

#Home .media {
    padding-right: 5px;
    color: whitesmoke;
}

#Home .media:hover {
    color: rgb(125, 172, 235);
}

#Home .header-content {
    color: #333;
    height: 100%;
    /* padding: 75px 25px; */
}

#Home .down-arrow {
    transform: rotate(90deg);
    margin-top: 30px;
    font-size: 30px;
    transition: 0.5s all;
}

#Home .down-arrow:hover {
    margin-top: 40px;
    font-size: 45px;
    transition: 0.5s all;
    cursor: pointer;
}

#Home .body-section {
    padding-top: 40px;
    padding-bottom: 40px;
}

#Home .scholarship {
    padding: 40px;
    margin-top: -40px;
}

#Home .donation-btn {
    background-color: white;
    border: 1px solid #2a9df4;
    padding: 8px 24px;
    color: #2a9df4;
    box-shadow: none;
    border-radius: 2em;
    transition: 0.3s all;
}

#Home .donation-btn:hover {
    background-color: #2a9df4;
    color: white;
    transition: 0.3s all;
}

.home-body .partners {
    margin-top: -30px;
    transition: 0.3s ease-in-out;
}

.home-body .partners:hover {
    margin-top: -35px;
    margin-bottom: 5px;
    transition: 0.3s ease-in-out;

}

#scholarship {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../images/hero-image.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
    background-color: #d5edff;
    /* height: 85vh; */
}

#oasis-stats .stats-box {
    background-color: #2a9df4 !important;
    padding: 15px;
}

#oasis-stats .stats-box {
    color: whitesmoke;
}

.apply-button {
    padding: 8px 20px;
    border-radius: 5em;
    color: #2a9df4;
    border: 2px solid #2a9df4;
    background-color: white;
    transition: 0.1s ease-in-out;
}

.apply-button:hover {
    color: whitesmoke;
    background-color: #2a9df4;
    transition: 0.1s ease-in-out;
}

.contact-field {
    color: #2a9df4 !important;
    border: #2a9df4 !important;
}

a {
    text-decoration: none !important;
}

#Home .scholarship-winners img {
    width: 100%;
    height: auto;
    padding-bottom: 0.5rem
}